import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import axios from '../lib/axios';
import { verify } from '../utils/jwt';

const initialState = {
  isAuthenticated: false,
  isAdmin: false,
  isInitialized: false,
  user: null
};

const setSession = (accessToken) => {
  if (accessToken) {
	localStorage.setItem('accessToken', accessToken);
	axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
	localStorage.removeItem('accessToken');
	delete axios.defaults.headers.common.Authorization;
  }
};

const handlers = {
  INITIALIZE: (state, action) => {
	const { isAuthenticated, isAdmin, user } = action.payload;

	return {
	  ...state,
	  isAuthenticated,
	  isAdmin,
	  isInitialized: true,
	  user
	};
  },
  LOGIN: (state, action) => {
    const { user, authenticated, isAdmin } = action.payload;

    return {
      ...state,
      isAuthenticated: authenticated,
	  isAdmin,
      user
    };
  },
  LOGOUT: (state) => ({
	...state,
	isAuthenticated: false,
	isAdmin: false,
	user: null
  }),
  REGISTER: (state, action) => {
	const { user } = action.payload;

	return {
	  ...state,
	  isAuthenticated: true,
	  isAdmin: false,
	  user
	};
  }
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
	const initialize = async () => {
	  try {
		const accessToken = window.localStorage.getItem('accessToken');

		if (accessToken && verifyToken(accessToken)) {
		  setSession(accessToken);

		  const response = await axios.get('/simple/user');
		  var { user, isAdmin } = response.data;

		  // Add access token to user data
		  user.token = accessToken;

		  dispatch({
			type: 'INITIALIZE',
			payload: {
			  isAuthenticated: true,
			  isAdmin,
			  user
			}
		  });
		} else {
		  dispatch({
			type: 'INITIALIZE',
			payload: {
			  isAuthenticated: false,
			  isAdmin: false,
			  user: null
			}
		  });
		}
	  } catch (err) {
		console.error(err);
		dispatch({
		  type: 'INITIALIZE',
		  payload: {
			isAuthenticated: false,
			isAdmin: false,
			user: null
		  }
		});
	  }
	};

	initialize();
  }, []);

  const verifyToken = async (token) => {
    var response = await axios.post('/authentication/token/verify', { token });
    return response && response.data && response.data.authenticated;
  };

  const login = async (client, password) => {
	const response = await axios.post('/authentication/login', {
	  client,
	  password
	});
	const { token, user, authenticated, isAdmin } = response.data;

	console.log('Axios Result Data: ', response.data);

	setSession(token);

	// Add access token to user data
	user.token = token;

	dispatch({
	  type: 'LOGIN',
	  payload: {
		user,
		authenticated,
		isAdmin
	  }
	});
  };

  const logout = async () => {
	setSession(null);
	dispatch({ type: 'LOGOUT' });
  };

  const register = async (email, name, password) => {
	const response = await axios.post('/api/authentication/register', {
	  email,
	  name,
	  password
	});
	const { accessToken, user } = response.data;

	window.localStorage.setItem('accessToken', accessToken);
	dispatch({
	  type: 'REGISTER',
	  payload: {
		user
	  }
	});
  };

  return (
	<AuthContext.Provider
	  value={{
		...state,
		platform: 'JWT',
		login,
		logout,
		register
	  }}
	>
	  {children}
	</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
