import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const RoleGuard = ({ redirect, active, children }) => {
  const { isAdmin } = useAuth();

  if ( !isAdmin ) {
    if ( redirect === true) {
      return <Navigate to="/" />;
    } else {
      return '';
    }
  }

  return <>{ children }</>;
};

RoleGuard.propTypes = {
  children: PropTypes.node
};

export default RoleGuard;
