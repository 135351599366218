import { experimentalStyled } from '@material-ui/core/styles';

const LogoRoot = experimentalStyled('svg')();

const Logo = (props) => (
  <LogoRoot
    height="55"
    version="1.1"
    viewBox="0 0 85 55"
    width="85"
    {...props}
  >
    <rect width="85" height="55" rx="12" fill="#ffffff"></rect>
    <path d="M20.8222 44.924C20.4382 44.924 20.0902 44.792 19.7782 44.528C19.4662 44.288 19.3102 43.916 19.3102 43.412V41.108C17.9902 40.964 16.8022 40.592 15.7462 39.992C14.6902 39.392 13.8622 38.516 13.2622 37.364C12.6622 36.212 12.3622 34.736 12.3622 32.936V22.964C12.3622 20.924 12.7342 19.304 13.4782 18.104C14.2462 16.904 15.2662 16.04 16.5382 15.512C17.8102 14.96 19.2382 14.684 20.8222 14.684C22.4062 14.684 23.8342 14.96 25.1062 15.512C26.4022 16.04 27.4222 16.904 28.1662 18.104C28.9102 19.304 29.2822 20.924 29.2822 22.964V32.936C29.2822 34.736 28.9822 36.212 28.3822 37.364C27.7822 38.516 26.9542 39.392 25.8982 39.992C24.8422 40.592 23.6422 40.964 22.2982 41.108V43.412C22.2982 43.916 22.1422 44.288 21.8302 44.528C21.5422 44.792 21.2062 44.924 20.8222 44.924ZM19.3102 36.824V34.916C19.3102 34.412 19.4662 34.052 19.7782 33.836C20.0902 33.596 20.4382 33.476 20.8222 33.476C21.2062 33.476 21.5422 33.596 21.8302 33.836C22.1422 34.052 22.2982 34.412 22.2982 34.916V36.824C23.0182 36.584 23.5822 36.152 23.9902 35.528C24.3982 34.88 24.6022 34.016 24.6022 32.936V22.964C24.6022 21.548 24.2662 20.504 23.5942 19.832C22.9222 19.136 21.9982 18.788 20.8222 18.788C19.6462 18.788 18.7222 19.136 18.0502 19.832C17.4022 20.504 17.0782 21.548 17.0782 22.964V32.936C17.0782 34.016 17.2702 34.88 17.6542 35.528C18.0382 36.152 18.5902 36.584 19.3102 36.824Z" fill="#111111"></path>
    <path d="M34.1501 41C33.7421 41 33.2981 40.868 32.8181 40.604C32.3381 40.316 32.0981 39.944 32.0981 39.488V35.996C32.0981 35.06 32.3741 34.184 32.9261 33.368C33.4781 32.528 34.1981 31.736 35.0861 30.992C35.9741 30.248 36.9101 29.528 37.8941 28.832C38.8781 28.136 39.8021 27.428 40.6661 26.708C41.5541 25.988 42.2741 25.256 42.8261 24.512C43.4021 23.768 43.6901 22.976 43.6901 22.136C43.6901 21.632 43.5701 21.14 43.3301 20.66C43.1141 20.156 42.7541 19.748 42.2501 19.436C41.7701 19.1 41.1341 18.932 40.3421 18.932C39.4781 18.932 38.7221 19.184 38.0741 19.688C37.4501 20.168 37.1381 20.972 37.1381 22.1C37.1381 22.532 36.9221 22.928 36.4901 23.288C36.0581 23.648 35.4581 23.828 34.6901 23.828C34.0661 23.828 33.5621 23.648 33.1781 23.288C32.7941 22.928 32.6021 22.268 32.6021 21.308C32.6021 19.94 32.9621 18.764 33.6821 17.78C34.4021 16.772 35.3501 16.004 36.5261 15.476C37.7261 14.948 38.9981 14.684 40.3421 14.684C41.7341 14.684 43.0421 14.984 44.2661 15.584C45.4901 16.16 46.4741 16.988 47.2181 18.068C47.9861 19.148 48.3701 20.456 48.3701 21.992C48.3701 23.264 48.0941 24.416 47.5421 25.448C46.9901 26.48 46.2821 27.416 45.4181 28.256C44.5541 29.096 43.6301 29.864 42.6461 30.56C41.6861 31.232 40.7741 31.868 39.9101 32.468C39.0461 33.044 38.3381 33.62 37.7861 34.196C37.2341 34.772 36.9581 35.372 36.9581 35.996V36.896H46.9661C47.3741 36.896 47.7221 37.1 48.0101 37.508C48.2981 37.892 48.4421 38.36 48.4421 38.912C48.4421 39.464 48.2981 39.956 48.0101 40.388C47.7221 40.796 47.3741 41 46.9661 41H34.1501Z" fill="#111111"></path>
    <path d="M53.8379 41C53.2619 41 52.7219 40.88 52.2179 40.64C51.7379 40.376 51.4979 39.992 51.4979 39.488V17.024C51.4979 16.16 51.7379 15.56 52.2179 15.224C52.7219 14.864 53.2619 14.684 53.8379 14.684C54.4139 14.684 54.8939 14.744 55.2779 14.864C55.6859 14.984 56.0579 15.224 56.3939 15.584C56.7539 15.944 57.1259 16.472 57.5099 17.168L61.9379 25.232L66.3299 17.168C66.7619 16.472 67.1339 15.944 67.4459 15.584C67.7819 15.224 68.1539 14.984 68.5619 14.864C68.9699 14.744 69.4499 14.684 70.0019 14.684C70.6019 14.684 71.1419 14.864 71.6219 15.224C72.1019 15.56 72.3419 16.16 72.3419 17.024V39.488C72.3419 39.992 72.0899 40.376 71.5859 40.64C71.1059 40.88 70.5779 41 70.0019 41C69.4259 41 68.8859 40.88 68.3819 40.64C67.9019 40.376 67.6619 39.992 67.6619 39.488V24.044L63.4859 31.532C63.2699 31.868 63.0179 32.12 62.7299 32.288C62.4419 32.432 62.1539 32.504 61.8659 32.504C61.6019 32.504 61.3259 32.432 61.0379 32.288C60.7739 32.144 60.5339 31.892 60.3179 31.532L56.1779 23.792V39.488C56.1779 39.992 55.9379 40.376 55.4579 40.64C54.9779 40.88 54.4379 41 53.8379 41Z" fill="#111111"></path>
  </LogoRoot>
);

export default Logo;