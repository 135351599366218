import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Drawer, Hidden, Link, Typography } from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import Template from '../../icons/Template';
import UserIcon from '../../icons/User';
import UsersIcon from '../../icons/Users';
import LockIcon from '../../icons/Lock';
import MailIcon from '../../icons/Mail';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import RoleGuard from '../RoleGuard';

const sections = [
  {
    title: 'General',
    items: [
      {
        title: 'API Overview',
        path: '/dashboard',
        icon: <ChartSquareBarIcon fontSize="small" />
      },
      {
        title: 'Account',
        path: '/dashboard/account',
        icon: <UserIcon fontSize="small" />
      }
    ]
  },
  {
    title: 'Management',
    items: [
      {
        title: 'Customers',
        path: '/dashboard/customers',
        icon: <UsersIcon fontSize="small" />,
        admin: true
      },
      {
        title: 'API Integrations',
        icon: <Template fontSize="small" />,
        path: '/dashboard/integrations'
      },
      {
        title: 'Access Tokens (OAT)',
        icon: <LockIcon fontSize="small" />,
        path: '/dashboard/keys'
      },
      {
        title: 'Form Submissions',
        icon: <MailIcon fontSize="small" />,
        path: '/dashboard/forms/submissions'
      },
    ]
  }
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 40,
                  width: 'auto'
                }}
              />
            </RouterLink>
          </Box>
        </Hidden>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            <RouterLink to="/dashboard/account">
              {/* <Avatar
                src={user.avatar}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48
                }}
              /> */}
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {user.name}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                Your plan:
                {' '}
                <Link
                  color="primary"
                  component={RouterLink}
                  to="/dashboard/account"
                >
                  {user.role === 3 ? 'Admin' : user.plan}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            Need Help?
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            Your plan incudes email support
          </Typography>
          <Button
            color="primary"
            component={RouterLink}
            fullWidth
            sx={{ mt: 2 }}
            to="/contact"
            variant="contained"
          >
            Get in touch with us
          </Button>
        </Box>
        <Divider />
        <Box sx={{ pt: 2 }}>
          <Link
            href="https://olokoo.com/privacy-policy"
            target="_blank" fontSize="small"
            color="textSecondary"
            variant="subtitle2"
            sx={{
              m: 2,
              display: 'block'
            }}
            >
              Privacy Policy
          </Link>
          <Link
            href="https://olokoo.com/terms-of-service"
            target="_blank" fontSize="small"
            color="textSecondary"
            variant="subtitle2"
            sx={{
              m: 2
            }}
            >
              Terms of Service
          </Link>
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280
            }
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280
            }
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
